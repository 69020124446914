/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'tweetie': {
    	init: function(){
	    // Tweetie
	    // https://github.com/sonnyt/Tweetie
		    $('.tweet').twittie({
			    'count': 1,
			    'username': 'SackvilleTownNB',
				'apiPath': '../wp-content/themes/sackville/bower_components/Tweetie/api/tweet.php',
				'template': '<h3>{{screen_name}} &nbsp; {{date}}</h3> <p>{{tweet}}</p> ',
				'dateFormat' : '%b %d',
				'hideReplies': true, 
		    	}
		    );
    	}
    },
    'googlemap' : {
    	init: function(){        	
    		// Google Map
    		function initMap() {
              var directions = document.getElementById('directions-search');
                            
    		  var directionsService = new google.maps.DirectionsService;
    		  var directionsDisplay = new google.maps.DirectionsRenderer;
    		  var map = new google.maps.Map(document.getElementById('map'), {
    		    zoom: 15,
    		    center: {lat: 45.897903, lng: -64.368280}
    		  });
    		  directionsDisplay.setMap(map);

    		  var onChangeHandler = function() {
    		    calculateAndDisplayRoute(directionsService, directionsDisplay);
    		  };
    		  directions.addEventListener('click', onChangeHandler);
    		  $(document).on('keypress', '#start', function(e){
    		    if (e.which == 13){
    		      calculateAndDisplayRoute(directionsService, directionsDisplay);
    		    }
    		  });
    		}

    		function calculateAndDisplayRoute(directionsService, directionsDisplay) {
    		  directionsService.route({
    		    origin: document.getElementById('start').value,
    		    destination: 'sackville, NB',
    		    travelMode: google.maps.TravelMode.DRIVING
    		  }, function(response, status) {
    		    if (status === google.maps.DirectionsStatus.OK) {
    		      directionsDisplay.setDirections(response);
    		    } else {
    		      window.alert('Directions request failed due to ' + status);
    		    }
    		  });
    		}

    		if($('#map').length){
    		  initMap();
    		}
    	}
    },
    'instafeed' : {
    	init: function(){
	     // instafeed.js
		// https://github.com/stevenschobert/instafeed.js
		// https://github.com/stevenschobert/instafeed.js/issues/377
		
		var limitWidth; // Limit number of items on small screens
		var width = $(window).width();
		
		if ( width <= 768) {
		    limitWidth = 4;
		} else {
		    limitWidth = 8;
		}
		
		var feed = new Instafeed({
			get: 'user',
	        userId: '2008936795',
	        accessToken: '2008936795.2fabd14.cd921e3f6c7c4ea6bfef5954f44644e8',
			clientId: '2fabd147aa5a4d249bf015f8baa0f921',
			limit: limitWidth,
			template: '<div><a title="{{caption}}" target="_blank" href="{{link}}"><img alt="{{caption}}" src="{{image}}" /></a></div>',
			resolution: 'low_resolution',
			});

		feed.run();    
    	}
    },
    'common': {
      init: function() {
	  // JavaScript to be fired on all pages
	  
	  
	  $.fn.equalHeight = function() {
		    var maxHeight = 0;
		    return this.each(function(index, box) {
		        var boxHeight = $(box).height();
		        maxHeight = Math.max(maxHeight, boxHeight);
		    }).height(maxHeight);
		};
			  
	   $('.match-height').equalHeight();

	  $(window).resize(function(){
		    $('.match-height').css('height','auto');
		    $('.match-height').equalHeight();
		});
		
		
		/* Tourism Campaign 2020 */

		$('#open-video').on('click',function() {
			var video = $('#lightbox-video iframe');
			$(video).attr('src', $(video).attr('src').toString()+'&autoplay=1'); // adding autoplay to the URL
			$('#video').css('opacity',0);
			$('#video').css('display','block');
			$('#video').animate({opacity: 1},500,function(){ 


			});
		});
		 
		// Hiding the lightbox and removing YouTube autoplay
		$('#close-video').on('click',function() {
			var video = $('#lightbox-video iframe');
			var src = $(video).attr('src').replace('&autoplay=1','');

			$(video).attr('src',src); // removing autoplay form url
			$('#video').fadeOut(500,function(){ 

			});
		});

		$('#slick-walking-tours').slick({
			slidesToShow: 2,
			variableWidth: true,
			infinite: true,
			prevArrow: '<button type="button" class="tourism-slick-prev"></button>',
			nextArrow: '<button type="button" class="tourism-slick-next"></button>',
			responsive: [{

					breakpoint: 990,
					settings: {
						slidesToShow: 2,
						centerMode: true,
						initialSlide: 1,
						infinite: true
					}

				}, {

					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						centerMode: true,
						initialSlide: 1,
						infinite: true
					}

				}]
		});

		/* end Tourism Campaign 2020


	 // Force all featured-pages.php text boxes to be the same height
	 // Get an array of all element heights
	/*  var elementHeights = $('.match-height').map(function() {
	    return $(this).height();
	  }).get();
	  	
	  // Math.max takes a variable number of arguments
	  // `apply` is equivalent to passing each height as an argument
	  var maxHeight = Math.max.apply(null, elementHeights);
	
	  // Set each height to the max height
	  $('.match-height').height(maxHeight);
	  
	  
	  // Now, if a user resizes the screen, let's do that all over again:
	 /* 
	   $(window).resize(function() {
		   // Clear current height attribute
		   $('.match-height').css('height', '');
					
			var elementHeights = $('.match-height').map(function() {
			    return $(this).height();
			}).get();
		  	var nuHeight = Math.max.apply(null, elementHeights);
		  	$('.match-height').css("height", nuHeight);
			});
	  */
	  	  
    
    // Slick slider for template_explore_festivals
	  $('.festival-slides').slick({
		  infinite: true,
		  slidesToShow: 3,
		  slidesToScroll: 1,
		  arrows: true,
		  speed:500,
		  adaptiveHeight: true,
		  responsive: [
		    {
		      breakpoint: 769,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 1,
		        infinite: true,
		      }
		    },
		    {
		      breakpoint: 500,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1,
		        infinite: true,
		      }
		    }
		  ]
		});
		
		
	$('.show-all').on('click', function(){ // Kill slick slider on Festivals & Events page/slider
	   $('.festival-slides').slick('unslick').addClass('unslicked row'); // Kill the slider, add .unslicked class
	   $('.single-slide').addClass( "col-md-4 col-sm-6 col-xs-12" ); // Give Bootstrap classes to slides
	   $('.show-all-wrapper').css("display","none"); // Hide Show All button
	   $('.hide-all-wrapper').css("display","inline-block"); // Show Hide All link
	});
	
	$('.hide-all').on('click', function(){ // Rather than un-do everything from .show-all, reload the page
		location.reload();
	});

	        
    // Search expander
	$( ".search-icon" ).click(function() {
	  $( ".header-search" ).slideToggle( "fast", function() {
	    // Animation complete.
	  });
	});
	
	// Add BootStrap table classes to tables
	$( "table" ).addClass( "table table-bordered" );
	
	// But remove it from our WooCommerce pages 
	$( "table.tribe-events-tickets" ).removeClass( "table table-bordered" )

  
  
  // Add class to Community Events submission form, for Analytics tracking
 	 $("#tribe-community-events form").addClass( "community-add-event" );

  // Scroll to Top

  $('.scroll-to-top').on('click', function(){
      $('html, body').animate({
          scrollTop: 0
      }, 600);
  });
  
  // Hide Emergency Alert overlay when close icon clicked
  
  $( ".alert-off" ).click(function() {
	  $( ".emergency-wrapper" ).hide();
	});
	    
	    
	
	    
	// ReadMore.js
   	// For Sports Hall of Fame slider
   	// https://github.com/jedfoster/Readmore.js
   	$('.sports-wrapper').readmore({});

   	// ReadMore.js
   	// For Arts Wall slider
   	// https://github.com/jedfoster/Readmore.js
   	$('.arts-wall-wrapper').readmore({});
   	
	
	$('#directory-form').bind("keypress", function(e){
		   if(e.which === 13){
		    console.log('Done!');
		    e.preventDefault();
		    } 
		})
	
	
	// Open all external links in new tab
	// http://stackoverflow.com/questions/12987963/open-external-links-in-a-new-tab-without-jquery
	function externalLinks() {
	  for(var c = document.getElementsByTagName("a"), a = 0;a < c.length;a++) {
	    var b = c[a];
	    b.getAttribute("href") && b.hostname !== location.hostname && (b.target = "_blank")
	  }
	}
	;
	externalLinks();
		
	
	/* MOBILE NAV */
	// Mobile Nav controller
	$('.expand-nav svg').click(function(e) {
		$active = $(this).parent().children('.active');
		$active.attr('class',''); // jQuery doesn't support removeClass so manually set attr
			
		if($active.attr('id') === 'open-nav') { // Closed - Opening
			$('#close-nav').attr('class','active');
			$('.nav').fadeIn('fast');
						
		} else { // Open - Closing
			$('#open-nav').attr('class','active'); 
			$('.nav').fadeOut('fast');
			$('.nav ul.sub-menu').slideUp();
		}
		e.preventDefault();	
	});
	
	// Mobile Subnav Control
	$('.nav > li > a').click(function(e) {
		
		if($('.expand-nav').is(':visible')) { // checks to see if the nav is collapsed

			if(!$('.sub-menu:visible').is($(this).siblings('.sub-menu'))) {
				$('.sub-menu:visible').slideUp();
				$('.sub-menu:visible').parent().removeClass('open-menu-item');
			} 
			
			$(this).siblings().slideToggle();
			$(this).parent().toggleClass('open-menu-item');
			
			if($(this).parent().hasClass('menu-item-has-children')) {
				e.preventDefault();
			}
		}	
	});
	
	// resets the mobile nav if window has been enlarged
	$(window).resize(function() {			
		if($(window).width() > 1050) {
			$('.nav').removeAttr('style');
		}
	});
	
	// for mobile, move the overview link to nav-title element
	$('.nav > li > a').each(function() {
		$href = $(this).attr('href');
		
		$(this).siblings('.sub-menu').children('li.nav-title:first-child').children('a').attr('href',$href);
	});
	/* END MOBILE NAV */
	
        },
          
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        Sage.instafeed.init();

        Sage.tweetie.init();
        
        $('.slider').slick({
	        dots: true,
	        autoplay: true,
	        autoplaySpeed:4000,
			speed: 600,
	        fade:true, 
	        cssEase: 'linear',
	        infinite:true,
	        responsive: [
		    {
		      breakpoint: 769,
		      settings: {
		        fade:false,
		        speed:300,
		      }
		    }
		  ]
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'directory' : {
      init: function(){

        var listingsArray;

        $.ajax({
            url: php_ajax_url,
            type: "POST",
            data: "action=sackville_directory_feed",
            async: false,
            success: function(results) {
                var listings = JSON.parse(results);
                listingsArray = $.map(listings, function(el) { 
                  return el; 
                });
            },
            error: function() {
                console.log('Cannot retrieve data.');
            }
        });
        
        var directory = {};
        var directoryListings = $('.list');

        directory.renderHTML = function(z, listing){
          var output = '<div class="card card-directory col-lg-3 col-md-4 col-sm-6">' +
            '<div class="directory-image" style="background-image: url(' + listing.image + ')"></div>'+
            '<h3 class="name">' + listing.name + '</h3>'+
            '<p class="description">' + listing.description + '</p>'+
            '<span>' + ( listing.address !== '' ? listing.address + ', ' : '') + ( listing.city_province !== '' ? listing.city_province : '') + ( listing.postal !== '' ? ', ' + listing.postal : '' ) + '</span>'+
            '<span>' + 
            (listing.phone && !listing.is_private ? listing.phone + ' | ' : '') + 
            (listing.website !== '' ? '<a href="' + listing.website +  '">Visit Website</a>' : '') + 
            '</span>'+
            '</div>';
          directoryListings.append(output);
        };

        directory.init = function(){
          directoryListings.empty();
          $.each(listingsArray, function(i, listing){
            directory.renderHTML(i, listing);
          });
        }; 

        $('.directory-filters').on('change', function(e) {
          if($(e.target).hasClass('search')) 
            return;
            
          var option = $(this).val();
          var label = $(this).find('option:selected').text();
          directoryListings.empty();
          
          if(option === 'all'){
            directory.init();
          }
          
          $.each(listingsArray, function(i, listing) {
            if(listing.hasOwnProperty('category') && listing.category.indexOf(option) >= 0){ /* If category filter is contained within listing data */
              directory.renderHTML(i, listing);
            } else if(listing.hasOwnProperty('theme') && listing.theme.indexOf(option) >= 0){ /* If theme filter is contained within listing data */
              directory.renderHTML(i, listing);
            }
          });

          $('#current-results').html(label);
        });

        /* Get it started */
        directory.init();

        /* List JS live search */
        directory.options = {
          valueNames: [ 'name', 'description', 'category' ]
        };

        directory.directoryList = new List('directory', directory.options);
      }
    },
    'sports_hall_of_fame' : {
       init: function(){
       	// ReadMore.js
       	// For Sports Hall of Fame slider
       	// https://github.com/jedfoster/Readmore.js
       	$('.sports-wrapper').readmore({});
      }
    },
       'page_template_template_explore' : {
    	init: function(){
    		Sage.instafeed.init();
    		Sage.tweetie.init();
    	}
    },
    'page_template_template_explore_festivals' : {
    	init: function(){
    		Sage.instafeed.init();
    		Sage.tweetie.init();
    	}
    },
	'page_template_template_explore_sub' : {
		init: function(){
			Sage.instafeed.init();
			Sage.tweetie.init();
		}
	},
	'page_template_template_explore_vistors' : {
		init: function(){
			Sage.tweetie.init();
		}
	},
    'page_template_template_explore_movehere' : {
    	init: function(){
    		Sage.instafeed.init();
    		Sage.tweetie.init();
    	}
    },
    'page_template_template_explore_gettinghere' : {
    	init: function(){
    		Sage.instafeed.init();
    		Sage.googlemap.init();
    		Sage.tweetie.init();
    	}
    },
    'contact' : {
    	init: function(){
    		Sage.googlemap.init();
    	}
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.